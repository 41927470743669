import { gsap } from "gsap";

const $logo = document.querySelector('.logo')
const $hambuger = document.querySelector('.hambuger')
const $menuPage = document.querySelector('.mob_menu')
const buttons = document.querySelectorAll(".mob_button");

let isOpen = false;
let isRed = false;

gsap.set(".menu_path", {visibility:"visible"});

$hambuger.addEventListener("pointerenter", hambugerEnter);
$hambuger.addEventListener("click", hambugerClick);

function hambugerEnter() {
	console.log("hambuger enter")
}
function hambugerClick() {
	if (isOpen && !isRed) {
		isOpen = false
		gsap.to($menuPage, {
			left: "100vw",
			duration: 0.3
		});
		$logo.style.filter = "brightness(100%)";
		$hambuger.style.filter = "brightness(100%)";
		console.log('The variable is ' + isOpen);
	  }else if(isOpen && isRed){
		isOpen = false
		gsap.to($menuPage, {
			left: "100vw",
			duration: 0.3
		});
		$logo.style.filter = "brightness(1000%)";
		$hambuger.style.filter = "brightness(1000%)";
	  } else {
		isOpen = true
		gsap.to($menuPage, {
			left: 0,
			duration: 0.3
		});
		$logo.style.filter = "brightness(1000%)";
		$hambuger.style.filter = "brightness(1000%)";
		//console.log('The variable is ' + isOpen);
	  }
}

gsap.to(".mouse_wheel", {
	keyframes: [
		{height:8,y:10, ease: "sine.in",duration:.7,delay:.8},
		{height:18,y:15,duration:.2},
		{height:8,y:24,duration:.2},
		{height:8,y:10, ease: "sine.out",duration:.2, delay:3}
	],
	repeat: -1,
	transformOrigin: "center bottom"
})


/*--------------------
Listeners
--------------------*/
//document.addEventListener('touchmove', handleMouseMove)